import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'main',
      component: () => import('../components/Main.vue'),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import('../components/Home.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('../components/Dashboard/index.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/work-order',
          name: 'WorkOrderList',
          component: () => import('../components/WorkOrder/WorkOrderList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          // path: '/work-order/work-order-from-chart/year/:year/organization-structure-short-thai-name/:organizationStructureShortThaiName/status/:status',
          path: '/work-order/work-order-from-chart/',
          name: 'WorkOrderListFromChart',
          component: () => import('../components/WorkOrder/WorkOrderListFromChart.vue'),
          meta: {
            requiresAuth: true,
          },
          props: route => ({ year: route.query.year, organizationStructureShortThaiName: route.query.organizationStructureShortThaiName, status: route.query.status })
        },
        {
          path: '/purchase',
          name: 'PurchaseList',
          component: () => import('../components/Purchase/PurchaseList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/net-add',
          name: 'NetAddList',
          component: () => import('../components/NetAdd/NetAddList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/fund',
          name: 'FundList',
          component: () => import('../components/Fund/FundList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/asset',
          name: 'AssetList',
          component: () => import('../components/Asset/AssetList.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          // path: '/work-order/work-order-from-chart/year/:year/organization-structure-short-thai-name/:organizationStructureShortThaiName/status/:status',
          path: '/asset/asset-from-chart/',
          name: 'AssetListFromChart',
          component: () => import('../components/Asset/AssetListFromChart.vue'),
          meta: {
            requiresAuth: true,
          },
          props: route => ({ organizationStructureShortThaiName: route.query.organizationStructureShortThaiName, assetClassId: route.query.assetClassId, assetClassName: route.query.assetClassName })
        },
        // {
        //   path: '/work-order/:id/show',
        //   name: 'WorkOrderShow',
        //   component: () => import('../components/WorkOrder/WorkOrderShow/index.vue'),
        //   meta: {
        //     requiresAuth: true,
        //   },
        //   props: true,
        // },
        // {
        //   path: '/customer/create',
        //   name: 'CustomerCreate',
        //   component: () => import('../components/Customer/CreateCustomer.vue'),
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },
        // {
        //   path: '/customer/:id/edit',
        //   name: 'CustomerEdit',
        //   component: () => import('../components/Customer/EditCustomer.vue'),
        //   meta: {
        //     requiresAuth: true,
        //   },
        //   props: true,
        // },
        {
          path: '/profile',
          name: 'Profile',
          component: () => import('../components/User/Profile/index.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/contact',
          name: 'contact',
          component: () => import('../components/Contact.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/forbidden',
          name: 'forbidden',
          component: () => import('../components/403_Forbidden.vue'),
          meta: {
            requiresAuth: true,
          },
        },

      ],
    },
    {
      path: '/external-dashboard',
      name: 'external-dashboard',
      component: () => import('../components/Dashboard/indexExternal.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../components/Login.vue'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('../components/Login.vue'),
    },
    {
      path: '/user/authenticate/facebook/callback',
      name: 'AuthenticateFacebookCallback',
      component: () => import('../components/User/AuthenticateFacebookCallback.vue'),
    },
    {
      path: '/user/authenticate/google/callback',
      name: 'AuthenticateGoogleCallback',
      component: () => import('../components/User/AuthenticateGoogleCallback.vue'),
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // if (!store.getters.isLoggedIn) {
    if (!store.getters['user/isLoggedIn']) {
      next({ name: 'login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
