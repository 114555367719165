import axios from 'axios'

const login = ({
    commit
}, user) => {
    return new Promise((resolve, reject) => {
        commit('AUTH_REQUEST')
        axios({
                url: '/user/authenticate',
                data: user,
                method: 'POST'
            })
            .then((response) => {
                const {
                    token
                } = response.data.data
                const {
                    username
                } = response.data.data
                const {
                    fullname
                } = response.data.data
                const {
                    avatar
                } = response.data.data
                const {
                    permissions
                } = response.data.data
                // sessionStorage.setItem('token', token)
                // sessionStorage.setItem('username', username)
                // sessionStorage.setItem('userFullName', fullname)
                // sessionStorage.setItem('userAvatar', avatar)
                axios.defaults.headers.common.Authorization = `Bearer ${token}`
                commit('AUTH_SUCCESS', {
                    token,
                    username,
                    fullname,
                    avatar,
                    permissions,
                })
                resolve(response)
            })
            .catch((error) => {
                const loginErrorMessage = error.response.data.message
                commit('AUTH_ERROR', {
                    loginErrorMessage
                })
                // sessionStorage.removeItem('token')
                reject(error)
            })

    })
}

const loginFacebook = ({
    commit
}, userFacebookCode) => {
    return new Promise((resolve, reject) => {
        commit('AUTH_REQUEST')
        axios({
                url: '/user/authenticate/facebook/login/callback',
                params: {
                    code: userFacebookCode
                },
                method: 'get'
            })
            .then((response) => {
                const {
                    token
                } = response.data.data
                const {
                    username
                } = response.data.data
                const {
                    fullname
                } = response.data.data
                const {
                    avatar
                } = response.data.data
                const {
                    permissions
                } = response.data.data
                // sessionStorage.setItem('token', token)
                // sessionStorage.setItem('username', username)
                // sessionStorage.setItem('userFullName', fullname)
                // sessionStorage.setItem('userAvatar', avatar)
                axios.defaults.headers.common.Authorization = `Bearer ${token}`
                commit('AUTH_SUCCESS', {
                    token,
                    username,
                    fullname,
                    avatar,
                    permissions,
                })
                resolve(response)
            })
            .catch((error) => {
                const loginErrorMessage = error.response.data.message
                commit('AUTH_ERROR', {
                    loginErrorMessage
                })
                // sessionStorage.removeItem('token')
                reject(error)
            })
    })
}

const loginGoogle = ({
    commit
}, userGoogleCode) => {
    return new Promise((resolve, reject) => {
        commit('AUTH_REQUEST')
        axios({
                url: '/user/authenticate/google/login/callback',
                params: {
                    code: userGoogleCode
                },
                method: 'get'
            })
            .then((response) => {
                const {
                    token
                } = response.data.data
                const {
                    username
                } = response.data.data
                const {
                    fullname
                } = response.data.data
                const {
                    avatar
                } = response.data.data
                const {
                    permissions
                } = response.data.data
                // sessionStorage.setItem('token', token)
                // sessionStorage.setItem('username', username)
                // sessionStorage.setItem('userFullName', fullname)
                // sessionStorage.setItem('userAvatar', avatar)
                axios.defaults.headers.common.Authorization = `Bearer ${token}`
                commit('AUTH_SUCCESS', {
                    token,
                    username,
                    fullname,
                    avatar,
                    permissions,
                })
                resolve(response)
            })
            .catch((error) => {
                const loginErrorMessage = error.response.data.message
                commit('AUTH_ERROR', {
                    loginErrorMessage
                })
                // sessionStorage.removeItem('token')
                reject(error)
            })
    })
}

const changeAvatar = ({
    commit
}, avatar) => {
    return new Promise((resolve) => {
        commit('CHANGE_AVATAR', {
            avatar
        })
        // sessionStorage.setItem('userAvatar', avatar)
        resolve()
    })
}

const changeFullName = ({
    commit
}, fullname) => {
    return new Promise((resolve) => {
        commit('CHANGE_FULLNAME_REQUEST')
        axios
            .post("/user/profile", {
                fullname: fullname
            })
            .then((response) => {
                const {
                    fullname
                } = response.data.data

                // sessionStorage.setItem('userFullName', fullname)
                commit('CHANGE_FULLNAME_SUCCESS', {
                    fullname
                })
                resolve(response)

            })
            .catch(function () {});

    })
}

const logout = ({
    commit
}) => {
    return new Promise((resolve) => {
        commit('LOGOUT')
        // sessionStorage.removeItem('token')
        // sessionStorage.removeItem('username')
        // sessionStorage.removeItem('userFullName')
        // sessionStorage.removeItem('userAvatar')
        sessionStorage.clear()
        delete axios.defaults.headers.common.Authorization
        resolve()
    })
}

export {
    login,
    loginFacebook,
    loginGoogle,
    changeAvatar,
    changeFullName,
    logout
}