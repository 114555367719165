const isLoggedIn = state => !!state.token
const isLoginError = state => (state.status === 'error')
const isLoginLoading = state => (state.status === 'loading')
const loginErrorMessage = state => state.loginErrorMessage
const authStatus = state => state.status
const username = state => state.username
const userFullName = state => state.userFullName
const userAvatar = state => state.userAvatar
const canCreateAsset = state => state.permissions.includes('canCreateAsset')
const canReadAsset = state => state.permissions.includes('canReadAsset')
const canUpdateAsset = state => state.permissions.includes('canUpdateAsset')
const canDeleteAsset = state => state.permissions.includes('canDeleteAsset')
const canUploadAsset = state => state.permissions.includes('canUploadAsset')
const canCreateNetAdd = state => state.permissions.includes('canCreateNetAdd')
const canReadNetAdd = state => state.permissions.includes('canReadNetAdd')
const canUpdateNetAdd = state => state.permissions.includes('canUpdateNetAdd')
const canDeleteNetAdd = state => state.permissions.includes('canDeleteNetAdd')
const canUploadNetAdd = state => state.permissions.includes('canUploadNetAdd')
const canCreatePurchase = state => state.permissions.includes('canCreatePurchase')
const canReadPurchase = state => state.permissions.includes('canReadPurchase')
const canUpdatePurchase = state => state.permissions.includes('canUpdatePurchase')
const canDeletePurchase = state => state.permissions.includes('canDeletePurchase')
const canUploadPurchase = state => state.permissions.includes('canUploadPurchase')
const canCreateWorkOrder = state => state.permissions.includes('canCreateWorkOrder')
const canReadWorkOrder = state => state.permissions.includes('canReadWorkOrder')
const canUpdateWorkOrder = state => state.permissions.includes('canUpdateWorkOrder')
const canDeleteWorkOrder = state => state.permissions.includes('canDeleteWorkOrder')
const canUploadWorkOrder = state => state.permissions.includes('canUploadWorkOrder')
const canCreateFund = state => state.permissions.includes('canCreateFund')
const canReadFund = state => state.permissions.includes('canReadFund')
const canUpdateFund = state => state.permissions.includes('canUpdateFund')
const canDeleteFund = state => state.permissions.includes('canDeleteFund')
const canUploadFund = state => state.permissions.includes('canUploadFund')
const canCreateUser = state => state.permissions.includes('canCreateUser')
const canReadUser = state => state.permissions.includes('canReadUser')
const canUpdateUser = state => state.permissions.includes('canUpdateUser')
const canDeleteUser = state => state.permissions.includes('canDeleteUser')

export {
    isLoggedIn,
    isLoginError,
    isLoginLoading,
    loginErrorMessage,
    authStatus,
    username,
    userFullName,
    userAvatar,
    canCreateAsset,
    canReadAsset,
    canUpdateAsset,
    canDeleteAsset,
    canUploadAsset,
    canCreateNetAdd,
    canReadNetAdd,
    canUpdateNetAdd,
    canDeleteNetAdd,
    canUploadNetAdd,
    canCreatePurchase,
    canReadPurchase,
    canUpdatePurchase,
    canDeletePurchase,
    canUploadPurchase,
    canCreateWorkOrder,
    canReadWorkOrder,
    canUpdateWorkOrder,
    canDeleteWorkOrder,
    canUploadWorkOrder,
    canCreateFund,
    canReadFund,
    canUpdateFund,
    canDeleteFund,
    canUploadFund,
    canCreateUser,
    canReadUser,
    canUpdateUser,
    canDeleteUser,
}